import smoothscroll from 'smoothscroll-polyfill';
import LazyLoad from "vanilla-lazyload";

smoothscroll.polyfill();

try {
    window.$ = window.jQuery = require('jquery');

    //require('bootstrap');
    require('slick-carousel/slick/slick');

    require('magnific-popup/dist/jquery.magnific-popup');
} catch (e) {a
    console.log(e);
}

$('a[href*="#"]').click(function (e) {
    e.preventDefault();

    if ($(this.hash).length === 0) {
        window.location.href = this.href;
    }

    $(this.hash)[0].scrollIntoView({behavior: "smooth"});
    $('nav.top-nav .hamburger').removeClass('active');
    $('nav.top-nav [role="navigation"]').removeClass('active');
});

// navigation
$('nav.top-nav ul').attr('role', 'navigation');
require('./hamburger');

// slider
let $slider = $('.home .slider');

$slider.find('img').each(function (k, img) {
    $(img).appendTo($slider);
});

$slider.find('p').remove();

$slider.slick({
    rtl: document.dir === 'rtl',
    fade: true,
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 1000
});

// gallery
$('.gallery-item').each(function (k, gallery) {
    $(gallery).magnificPopup({
        delegate: 'a.mfp',
        type: 'image',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
    });
});

$('.gallery-item .card-columns').each(function (k, cards) {
    let count = $(cards).find('.card').length;

    if (count === 1) {
        $(cards).css('column-count', 1);
    } else if (count % 4 === 0) {
        $(cards).css('column-count', 4);
    } else if (count % 3 === 0) {
        $(cards).css('column-count', 3);
    } else if (count % 2 === 0) {
        $(cards).css('column-count', 2);
    }
});

// about
$('body.about .about-wrapper .col').css('height', 'auto');


// contact
$('#contact .boat [role="button"]').click(function (e) {
    e.preventDefault();
    $('#contact .boat-bridge').toggleClass('active');
});

$(function () {
    let myLazyLoad = new LazyLoad({
        elements_selector: '.lazy',
    });
    myLazyLoad.update();
});
